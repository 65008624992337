<template>
  <div class="site-edition-container pt-4 pb-4">
    <div class="mb-5">
      <table
        class="table b-table table-bordered table-sm b-table-no-border-collapse commodity-table"
      >
        <thead>
          <tr>
            <th>Commodity Code</th>
            <th>Shipper</th>
            <th>Supplier</th>
            <th>Account Of</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(code, index) in commodityProfiles" :key="index">
            <td>
              {{ code.commodityCode }}
            </td>
            <td>
              <b-form-select
                  :id="'shipper-field-' + index"
                  placeholder="Shipper"
                  v-model="commodityProfiles[index].shipper"
                  :options="shipperOptions"
                  class="dropdown-sm"
                  :disabled="!canUpdate"                >
                </b-form-select>
            </td>
            <td>
              <b-form-select
                  :id="'supplier-field-' + index"
                  placeholder="Supplier"
                  v-model="commodityProfiles[index].supplier"
                  :options="supplierOptions"
                  class="dropdown-sm"
                  :disabled="!canUpdate"                >
                </b-form-select>
            </td>
            <td>
              <b-form-select
                  :id="'account-of-field' + index"
                  placeholder="Account of"
                  v-model="commodityProfiles[index].accountOf"
                  :options="accountOfOptions"
                  class="dropdown-sm"
                  :disabled="!canUpdate"                >
                </b-form-select>
            </td>
            <td class="text-center action-cell" v-if="canUpdate">
              <b-button
                variant="kag-dark-blue"
                class="delete-btn btn"
                @click="addNewCommodityProfile(index)"
                :disabled="!isValidToSave(index)"
              >
                Update
              </b-button>
            </td>
          </tr>
          <tr v-for="(profile, index) in profiles" :key="'b-' + index">
            <td width="auto">
              {{ profile.commodityCode }}
            </td>
            <td width="auto">
              <b-form-select
                  :id="'shipper-field-' + index"
                  placeholder="Shipper"
                  v-model="profile.shipper"
                  :options="shipperOptions"
                  class="dropdown-sm"
                  :disabled="!canUpdate"
                  @change="handleChange(index)"
              >
              </b-form-select>
            </td>
            <td width="auto">
              <b-form-select
                  :id="'supplier-field-' + index"
                  placeholder="Supplier"
                  v-model="profile.supplier"
                  :options="supplierOptions"
                  class="dropdown-sm"
                  :disabled="!canUpdate"
                  @change="handleChange(index)"
                >
              </b-form-select>
            </td>
            <td width="auto">
              <b-form-select
                  :id="'account-of-field-' + index"
                  placeholder="Account of"
                  v-model="profile.accountOf"
                  :options="accountOfOptions"
                  class="dropdown-sm"
                  :disabled="!canUpdate"
                  @change="handleChange(index)"
                >
              </b-form-select>
            </td>
            <td class="text-center action-cell" v-if="canUpdate">
              <b-button
                  variant="kag-dark-blue"
                  class="delete-btn btn"
                  @click="updateCommodityProfile(profile)"
                  :disabled="!canUpdate || !hasChanged[index]"
                >
                  Update
                </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import toastMixin from '@/mixins/shared/toastMixin'

const commodityRepo = RepositoryFactory.get('CommodityProfileRepository')

export default {
  props: {
    tanks: Array,
    shipperOptions: Array,
    supplierOptions: Array,
    accountOfOptions: Array,
    commodityCodes: Array,
    companyId: Number,
    canUpdate: Boolean
  },
  mixins: [
    toastMixin
  ],
  data () {
    return {
      commodityProfiles: [],
      hasChanged: [],
      profiles: []
    }
  },
  watch: {
    commodityCodes (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateCommodityCodes()
      }
    }
  },
  created () {
    this.getCommodityCodes()
  },
  methods: {
    updateCommodityCodes () {
      this.profiles = []
      this.hasChanged = []
      this.commodityProfiles = []
      this.getCommodityCodes()
    },
    async getCommodityCodes () {
      const existingCommodityCodeProfiles = await commodityRepo.getCommodityProfileByCompanyId(this.companyId)
      this.mapExistingCommodityCodeProfiles(existingCommodityCodeProfiles)
      this.mapUnassignedCommodityCodes(existingCommodityCodeProfiles)
    },
    mapExistingCommodityCodeProfiles (existingCommodityCodeProfiles) {
      for (const commodityProfile of existingCommodityCodeProfiles.data) {
        if (this.commodityCodes.some((tankCode) => commodityProfile.commodityCode === tankCode.commodityCode)) {
          const profile = {
            commodityCode: commodityProfile.commodityCode,
            shipper: commodityProfile.assignedShipperId,
            supplier: commodityProfile.assignedSupplierId,
            accountOf: commodityProfile.assignedAccountOfId
          }
          this.profiles.push(profile)
        }
      }
    },
    mapUnassignedCommodityCodes (existingCommodityCodeProfiles) {
      this.commodityCodes.map((tankCode) => {
        // If commodity code doesn't exist
        if (!existingCommodityCodeProfiles.data.some((commodityProfile) => commodityProfile.commodityCode === tankCode.commodityCode)) {
          this.commodityProfiles.push({
            commodityCode: tankCode.commodityCode,
            shipper: null,
            supplier: null,
            accountOf: null
          })
        }
      })
    },
    addNewCommodityProfile (index) {
      if (!this.canUpdate) return
      const payload = {
        companyId: this.companyId,
        commodityCode: this.commodityProfiles[index].commodityCode,
        assignedSupplierId: this.commodityProfiles[index].supplier,
        assignedShipperId: this.commodityProfiles[index].shipper,
        assignedAccountOfId: this.commodityProfiles[index].accountOf,
        isDeleted: false
      }
      commodityRepo.create(payload)
        .then(() => {
          this.showSuccess('Commodity Profile successfully saved')
          this.$emit('commodity-saved')
        })
        .catch(e => console.error(e))
    },
    updateCommodityProfile (profile) {
      if (!this.canUpdate) return
      const payload = {
        companyId: this.companyId,
        commodityCode: profile.commodityCode,
        assignedSupplierId: profile.supplier,
        assignedShipperId: profile.shipper,
        assignedAccountOfId: profile.accountOf,
        isDeleted: false
      }

      commodityRepo.update(payload).then(() => {
        this.showSuccess('Commodity Profile updated')
      })
      this.$emit('commodity-saved')
    },
    isValidToSave (index) {
      if (!this.canUpdate) return false
      const allPropertiesHaveValues = this.commodityProfiles[index].shipper !== null || this.commodityProfiles[index].supplier !== null || this.commodityProfiles[index].accountOf !== null
      return allPropertiesHaveValues
    },
    handleChange (index) {
      this.$set(this.hasChanged, index, true)
    }
  }
}
</script>

<style lang="scss">
.text-center {
  text-align: center;
}

.commodity-table {
  .action-cell {
    width: 90px;
    vertical-align: middle;
  }
}

.dropdown-sm {
  font-size: 1em;
}
</style>
