<template>
  <div class="sites">
    <b-row class="selection-container pt-3">
      <b-col cols="12" col xl="4" class="region-options">
        <b-form-group
          id="selection-region-fieldset"
          :label="regionsLabel()"
          label-for="selection-region"
        >
          <b-form-select
            id="selection-region"
            placeholder="Region"
            :value="selection.regionId"
            :options="regionOptions"
            @change="changeRegionFilter(selection, $event)"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" col xl="4" class="customer-options">
        <b-form-group
          id="selection-custom-group-fieldset"
          :label="customGroupLabel()"
          label-for="selection-custom-group"
        >
          <b-form-select
            id="selection-custom-group"
            placeholder="Custom Group"
            :value="selection.customGroupId"
            :options="customGroupOptions"
            @change="changeCGFilter(selection, $event)"

          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" col xl="4" class="site-options">
        <div class="pl-1 pb-2">{{ sitesLabel() }}</div>
          <VueBootstrapAutocomplete
            id="selection-site"
            inputClass="site-search"
            ref="selectionSite"
            :data="sitesForAutoComplete"
            v-model="siteQuery"
            @hit="selectedSite"
            @focus="siteSetFocus"
            @keyup.stop.prevent="siteKeyUp"
            @blur="siteOnBlur"
            :maxMatches="sitesToDisplay.length"
            placeholder="Site Search"
            :ieCloseFix="false"
            :showOnFocus="true"
            :showAllResults="siteShowAllResults"
            :disabledValues="sitesForAutoComplete"
            textVariant="primary"
            size="lg"
            :serializer="item => item.display"
            highlightClass="special-highlight-class"
            backgroundVariant="list-group-item-primary"
            autocomplete="one-time-code"
          >
          <template slot="append">
          <b-button size="sm" disabled>
            <font-awesome-icon icon="magnifying-glass" class="mr-1" aria-label="search" />Search
          </b-button>
          </template>
          </VueBootstrapAutocomplete>
      </b-col>
    </b-row>
    <b-row class="selection-container">
      <b-col cols="12" col xl="2">
        <b-form-group>
          <b-button id="sites-refresh" variant="kag-light-blue" size="sm" @click="refresh">
            <font-awesome-icon icon="rotate-right" class="mr-1" />Refresh Current Site Data
          </b-button>
        </b-form-group>
      </b-col>
      <b-col offset="4" cols="12" col xl="2">
      </b-col>
      <b-col cols="12" col xl="2">
        <b-form-group
          label="Inventory Filter"
          label-for="inventory-levels">
          <b-form-select
                id="inventory-levels"
                placeholder="InventoryLevels"
                v-model="inventoryType"
                @change="inventoryChange"
              >
                <b-form-select-option value="AllConsignees">All Consignees</b-form-select-option>
                <b-form-select-option value="NoInventory">No Inventory</b-form-select-option>
                <b-form-select-option value="CriticalInventory">Critical Inventory</b-form-select-option>
                <b-form-select-option value="LowInventory">Low Inventory</b-form-select-option>
                <b-form-select-option value="AboveThresholdInventory">Inventory Above Threshold</b-form-select-option>
                <b-form-select-option value="BelowThresholdInventory">Inventory Below Threshold</b-form-select-option>
              </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" col xl="2">
        <b-form-group>
          <b-form-checkbox class="btn-sitesort" v-model="sortChecked" name="check-button" switch @change="forceUpdate" :disabled="isFilteringStateNotSortable()">
            Days of supply
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="!site" class="text-center p-3">
      <h5>No information to display</h5>
    </div>

    <div v-if="site" id="sites-navigation-section">
      <b-row class="site-selection-container p-2 text-center">
        <b-col cols="12" col>
          <b-button id="sites-navigation-previous" variant="kag-dark-blue" @click="navigateSite('left')" :disabled="isNavigationDisabled('left')">
            <font-awesome-icon icon="arrow-left" class="mr-1" />Previous Site
          </b-button>
          <span class="ml-3"><b>{{ sitesNavLabel }}</b></span>
          <b-button id="sites-navigation-next" variant="kag-dark-blue" class="ml-3" @click="navigateSite('right')" :disabled="isNavigationDisabled('right')">
            Next Site <font-awesome-icon icon="arrow-right" class="ml-1" />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="site-details-container">
        <div class="col" :class="canCreateInventory ? 'col-xl-3' : 'col-12'">
          <div class="p-2">
            <SiteInformation :site="site"></SiteInformation>
          </div>
        </div>
        <b-col v-if="canCreateInventory" cols="12" col xl="9">
          <div class="p-2">
            <b-row class="scroll-section">
              <b-col cols="12" col xl="12">
                <ProductInventory
                  :tank-items="site.tanks"
                  :timeZoneId="site.timeZoneId"
                  :site="site"
                  v-on:inventory-added="refresh()"
                  @refresh-calculator="refreshCalculator('ProductInventory')"
                  @active-tab-action="actionWhenTabIsActive()"/>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row class="site-tabs-container pt-3">
        <b-col cols="12" col class="create-order-container m-1">
          <div class="d-flex flex-row-reverse">
            <b-button id="create-order" class="create-order-btn" variant="kag-dark-blue" @click="createOrder()" v-if="site.tanks && site.tanks.length && canCreateOrder">
              Create Order
            </b-button>

            <b-button
              id="review-order"
              class="review-order-btn"
              :class="{ 'no-tanks': !site.tanks || !site.tanks.length || !canCreateOrder }"
              :variant="ordersToReviewCount > 0 ? 'danger' : 'kag-dark-blue'"
              @click="reviewOrder()"
            >
              Review New Orders <span v-if="ordersToReviewCount > 0">({{ ordersToReviewCount }})</span>
            </b-button>

            <b-button
              v-if="site.tanks && site.tanks.length && canViewInventory"
              id="inventory-history"
              class="inventory-history-btn"
              :class="{ 'no-tanks': !site.tanks || !site.tanks.length || !canCreateOrder,
                        'extra-space' : ordersToReviewCount > 0 }"
              variant="kag-dark-blue"
              @click="openInventoryHistory()"
            >
              Inventory History
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" col>
          <b-tabs @activate-tab="activateTab" ref="siteTabs">
            <b-tab title="Forecast History" id="forecast-history" active>
              <div class="mt-3">
                <ForecastHistory
                  :tank-items="site.tanks"
                  :timeZoneId="site.timeZoneId"
                  :showInventoryReadingAlert="site.showInventoryReadingAlert == 'Y'"
                  v-on:sales-updated="refresh()"></ForecastHistory>
              </div>
              <div v-if="canViewOrder" class="mt-3">
                <strong>New Orders</strong>
                <PendingOrders
                  compId="sites"
                  :timeZoneId="site.timeZoneId"
                  :pendingOrders="site.fimOrders"
                >
                </PendingOrders>
              </div>
              <div v-if="canViewOrder" class="my-3">
                <strong>Orders</strong>
                <OrderHistory
                  :orders="site.orders"
                  :commodityClasses="commodityClasses"
                >
                </OrderHistory>
              </div>
            </b-tab>
            <b-tab id="site-rules-and-info" :title-link-class="{ 'bg-danger': site.restrictions.length > 0 }" v-if="canViewRestriction || canViewNote" title="Site Rules and Information">
              <div class="mt-3">
                <SiteRules :note-items="site.notes" :restriction-items="site.restrictions"></SiteRules>
              </div>
            </b-tab>
            <b-tab id="site-profile" title="Site Profile">
              <div class="mt-3">
                <SiteEdit
                  :site="site"
                  :region-options="regionOptionsUnassigned"
                  :custom-group-options="customGroupOptionsUnassigned"
                  :bill-to-options="billToOptions"
                  :can-update="canUpdateCompany"
                  v-on:company-updated="companyUpdated">
                </SiteEdit>
              </div>
            </b-tab>
            <b-tab id="calculator" v-if="UICalculatorEditor" title="Calculator">
              <div class="mt-3">
                <Calculator ref="calculatorComponent" :site="site" :tankOrders="site.orders" :tankPendingOrders="site.fimOrders"></Calculator>
              </div>
            </b-tab>
            <b-tab id="commodity-site-profile" title="Commodity Site Profile">
              <div class="m-3">
                <CommoditySiteProfile
                  ref="commoditySiteProfileComponent"
                  :companyId="this.site.id"
                  :commodityCodes="commodityCodes"
                  :shipper-options="shipperOptions"
                  :supplier-options="supplierOptions"
                  :account-of-options="accountOfOptions"
                  :can-update="canUpdateCompany"
                  v-on:commodity-saved="refresh()">
                </CommoditySiteProfile>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
    <CreateOrder ref="createOrderModal" @refresh-calculator="refreshCalculator('CreateOrder')"/>
    <ReviewOrder ref="reviewOrderModal" @refresh-calculator="refreshCalculatorFromModal()"/>
    <InventoryHistory ref="inventoryHistoryModal" @refresh-calculator="refreshCalculatorFromModal()"/>
  </div>
</template>

<script>
import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

import SiteInformation from '@/components/site/SiteInformation.vue'
import ProductInventory from '@/components/site/ProductInventory.vue'
import ForecastHistory from '@/components/site/ForecastHistory.vue'
import SiteRules from '@/components/site/SiteRules.vue'
import SiteEdit from '@/components/site/SiteEdit.vue'
import CreateOrder from '@/components/site/CreateOrder.vue'
import ReviewOrder from '@/components/site/ReviewOrder.vue'
import InventoryHistory from '@/components/site/InventoryHistory.vue'
import OrderHistory from '@/components/site/OrderHistory.vue'
import PendingOrders from '@/components/site/PendingOrders.vue'
import Calculator from '@/components/site/Calculator.vue'
import CommoditySiteProfile from '@/components/site/CommoditySiteProfile.vue'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'
import { EventBus } from '@/mixins/shared/event-bus'

const RegionRepo = RepositoryFactory.get('RegionRepository')
const CustomGroupRepo = RepositoryFactory.get('CustomGroupRepository')
const CompanyRepo = RepositoryFactory.get('CompanyRepository')
const FimOrderRepo = RepositoryFactory.get('FimOrderRepository')
const InventoryTypes = {
  AllConsignees: 'AllConsignees',
  NoInventory: 'NoInventory',
  CriticalInventory: 'CriticalInventory',
  LowInventory: 'LowInventory',
  AboveThresholdInventory: 'AboveThresholdInventory',
  BelowThresholdInventory: 'BelowThresholdInventory'
}

export default {
  name: 'Sites',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    SiteInformation,
    ProductInventory,
    ForecastHistory,
    SiteRules,
    SiteEdit,
    CreateOrder,
    ReviewOrder,
    InventoryHistory,
    OrderHistory,
    PendingOrders,
    Calculator,
    CommoditySiteProfile,
    VueBootstrapAutocomplete
  },
  data () {
    return {
      regionOptions: [],
      regionOptionsUnassigned: [],
      commodityCodes: [],
      customGroupOptions: [],
      customGroupOptionsUnassigned: [],
      shipperOptions: [],
      supplierOptions: [],
      accountOfOptions: [],
      billToOptions: [],
      sitesToDisplay: [],
      sites: [],
      site: null,
      selection: {},
      currentPage: 1,
      inventoryType: InventoryTypes.AllConsignees,
      commodityClasses: [],
      reloadSites: true,
      sortChecked: true,
      refreshTheSite: false,
      currentSiteIndex: 0,
      siteQuery: '',
      currentSiteId: '',
      siteShowAllResults: true,
      activeTabName: 'forecast-history'
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const responses = await Promise.all([
        RegionRepo.getAll(),
        CustomGroupRepo.getAll(),
        CompanyRepo.getUniqueIdsBy(CompanyRepo.types.SHIPPER),
        CompanyRepo.getUniqueIdsBy(CompanyRepo.types.SUPPLIER),
        CompanyRepo.getUniqueIdsBy(CompanyRepo.types.ACCOUNT_OF),
        CompanyRepo.getUniqueIdsBy(CompanyRepo.types.BILL_TO)
      ])
      to.meta.regions = responses[0] && responses[0].data ? responses[0].data : []
      to.meta.customGroups = responses[1] && responses[1].data ? responses[1].data : []
      to.meta.shippers = responses[2] && responses[2].data ? responses[2].data : []
      to.meta.suppliers = responses[3] && responses[3].data ? responses[3].data : []
      to.meta.accountOfs = responses[4] && responses[4].data ? responses[4].data : []
      to.meta.billTos = responses[5] && responses[5].data ? responses[5].data : []
      const sitesResponse = await CompanyRepo.getSites(-1, null, 'AllConsignees')
      to.meta.sites = sitesResponse && sitesResponse.data && sitesResponse.data.sites ? sitesResponse.data.sites : []
      next()
    } catch {
      next('/error')
    }
  },
  async beforeRouteUpdate (to, from, next) {
    const region = to.query.r || to.query.r === null ? to.query.r : null
    const cg = to.query.cg || to.query.cg === null ? to.query.cg : -1
    if ((from.query.r !== region) || (from.query.cg !== cg)) {
      this.sortChecked = false
    } else {
      this.sortChecked = to.query.sorted ? to.query.sorted : false
    }
    this.selection.regionId = region
    this.selection.customGroupId = cg
    this.inventoryType = to.query.i ? to.query.i : InventoryTypes.AllConsignees
    if (this.reloadSites) {
      await this.filterChanged(false)
    }
    this.reloadSites = true
    if (this.sitesToDisplay.length > 0) {
      const siteToLoad = this.sitesToDisplay.find((s) => s.html.substring(0, s.html.search('&')) === to.params.siteId)
      this.siteQuery = siteToLoad.html.split('&nbsp;').join(' ').replace(/  +/g, ' ')
      this.selection.siteId = siteToLoad ? siteToLoad.value : this.sitesToDisplay[0].value
      this.refresh()
    } else {
      this.selection.siteId = null
      this.site = null
    }
    next()
  },
  /**
 * The `beforeRouteLeave` function is called when the user is leaving the current page
 * @param to - The route that the user is navigating to.
 * @param from - The route that the user is leaving.
 * @param next - A function that must be called to resolve the hook. The action depends on the
 * arguments provided to next:
 */
  async beforeRouteLeave (to, from, next) {
    const ordersToReview = this.$store.getters['orders/amountOfOrdersToReview']
    if (ordersToReview > 0) {
      const leavePage = await this.$bvModal.msgBoxConfirm(`There are ${ordersToReview} order(s) to review.`, {
        headerBgVariant: 'warning',
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Leave',
        cancelTitle: 'Stay',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
      if (leavePage) {
        next()
      } else {
        EventBus.$emit('closeSideBar', true)
        next(false)
      }
    } else {
      next()
    }
  },
  mounted () {
    const listEls = document.querySelectorAll('.vbt-autocomplete-list')
    listEls.forEach(el => el.addEventListener('keypress', this.handleKeyPress))
  },
  beforeDestroy () {
    const listEls = document.querySelectorAll('.vbt-autocomplete-list')
    listEls.forEach(el => el.removeEventListener('keypress', this.handleKeyPress))
  },
  async created () {
    if (!this.canViewCompany) {
      if (this.canViewCustomGroup) {
        this.$router.push({ name: 'CustomGroups' })
      } else if (this.canViewRegion) {
        this.$router.push({ name: 'Regions' })
      } else {
        this.$router.push({ name: 'AccessDenied' })
      }
      return
    }
    this.getRegionsToShow()
    this.sortChecked = this.$route.query.sorted ? this.$route.query.sorted : false
    this.customGroupOptions = this.$route.meta.customGroups.map(global.mapUpperCase('description')).sort(global.sortBy('description')).map(global.mapOptions('id', 'description'))
    this.addUnassignedOption(this.customGroupOptions)
    this.addUnknownOption(this.customGroupOptions)
    this.customGroupOptionsUnassigned = this.$route.meta.customGroups.map(global.mapUpperCase('description')).sort(global.sortBy('description')).map(global.mapOptions('id', 'description'))
    this.addUnassignedOption(this.customGroupOptionsUnassigned)
    this.regionOptionsUnassigned = this.$route.meta.regions.map(global.mapUpperCase('description')).sort(global.sortBy('description')).map(global.mapOptions('id', 'description'))
    this.addUnassignedOption(this.regionOptionsUnassigned)
    this.shipperOptions = this.$route.meta.shippers.map(global.mapUpperCase('companyUniqueId')).sort(global.sortBy('companyUniqueId')).map(global.mapOptions('id', 'companyUniqueId'))
    this.addUnassignedOption(this.shipperOptions)
    this.supplierOptions = this.$route.meta.suppliers.map(global.mapUpperCase('companyUniqueId')).sort(global.sortBy('companyUniqueId')).map(global.mapOptions('id', 'companyUniqueId'))
    this.addUnassignedOption(this.supplierOptions)
    this.accountOfOptions = this.$route.meta.accountOfs.map(global.mapUpperCase('companyUniqueId')).sort(global.sortBy('companyUniqueId')).map(global.mapOptions('id', 'companyUniqueId'))
    this.addUnassignedOption(this.accountOfOptions)
    this.billToOptions = this.$route.meta.billTos.map(global.mapUpperCase('companyUniqueId')).sort(global.sortBy('companyUniqueId')).map(global.mapOptions('id', 'companyUniqueId'))
    this.addUnassignedOption(this.billToOptions)
    if (this.sortChecked) {
      if (!this.isFilteringStateNotSortable()) {
        this.sites = this.$route.meta.sites.map(global.mapUpperCase('companyUniqueIdAndAdress')).sort(global.sortIntBy('lowestTankDaysOfSupply')).map(global.mapOptions('id', 'companyUniqueIdAndAdress'))
      } else {
        this.sortChecked = false
      }
    }
    if (!this.sortChecked) {
      this.sites = this.$route.meta.sites.map(global.mapUpperCase('companyUniqueIdAndAdress')).sort(global.sortBy('companyUniqueIdAndAdress')).map(global.mapOptions('id', 'companyUniqueIdAndAdress'))
    }

    this.sitesToDisplay = this.sites
    if (this.sitesToDisplay.length > 0) {
      const siteToLoad = this.sitesToDisplay.find((s) => s.html.substring(0, s.html.search('&')) === this.$route.params.siteId)
      if (siteToLoad) {
        this.selection.siteId = siteToLoad.value
        this.siteQuery = siteToLoad.html.split('&nbsp;').join(' ').replace(/  +/g, ' ')
        this.refresh()
      } else {
        this.reloadSites = false
        this.selection.siteId = this.sitesToDisplay[0].value
        this.siteQuery = this.sitesToDisplay[0].html.split('&nbsp;').join(' ').replace(/  +/g, ' ')
        this.$router.replace({ path: `/site/${this.sitesToDisplay[0].html.substring(0, this.sitesToDisplay[0].html.search('&'))}` })
      }
    }
    if (this.customGroupOptions.length > 0) {
      this.selection.customGroupId = this.customGroupOptions[0].value
    }
    await this.$store.dispatch('orders/loadOrdersToReview')
  },
  methods: {
    siteSetFocus () {
      this.$refs.selectionSite.$el.children[0].children[0].select()
    },
    siteOnBlur () {
      this.siteShowAllResults = true
    },
    selectedSite (event) {
      const selectedIndex = this.sitesToDisplay.findIndex((s) => s.value === event.value)
      const selectedId = this.sitesToDisplay[selectedIndex].html.substring(0, this.sitesToDisplay[selectedIndex].html.search('&'))
      if (selectedId === this.currentSiteId) return
      this.siteSelect(event.value)
    },
    handleKeyPress (event) {
      event.target.click()
    },
    siteKeyUp (event) {
      this.siteShowAllResults = false
    },
    activateTab (next, prev) {
      this.activeTabName = this.$refs.siteTabs.tabs[next].id
      this.actionWhenTabIsActive(this.activeTabName)
    },
    actionWhenTabIsActive () {
      switch (this.activeTabName) {
        case 'forecast-history':
          break
        case 'site-rules-and-info':
          break
        case 'site-profile':
          break
        case 'calculator':
          this.resetCalculator()
          break
        case 'commodity-site-profile':
          this.refresh()
          break
        default:
          break
      }
    },
    getRegionsToShow () {
      RegionRepo.userProfileByEmail(this.getEmail)
        .then((newRegionsResponse) => {
          this.$route.meta.regionsToShow = newRegionsResponse && newRegionsResponse.data ? newRegionsResponse.data : []
          this.regionOptions = this.$route.meta.regionsToShow.map(global.mapUpperCase('description')).sort(global.sortBy('description')).map(global.mapOptions('id', 'description'))
          this.continueWithRegionOptions()
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred in userProfileByEmail'))
          this.continueWithRegionOptions()
        })
    },
    continueWithRegionOptions () {
      this.addUnassignedOption(this.regionOptions)
      if (this.regionOptions.length > 0) {
        this.selection.regionId = this.regionOptions[0].value
      }
    },
    resetCalculator () {
      this.$refs.calculatorComponent.updateData()
    },
    async forceUpdate () {
      if (this.isFilteringStateNotSortable()) {
        this.sortChecked = false
      }
      await this.filterChanged(true)
    },
    siteSelect (siteId) {
      this.reloadSites = false
      this.currentSiteIndex = this.sitesToDisplay.findIndex((s) => s.value === siteId)
      this.currentSiteId = this.sitesToDisplay[this.currentSiteIndex].html.substring(0, this.sitesToDisplay[this.currentSiteIndex].html.search('&'))
      this.$router.push({ name: 'Sites', params: { siteId: this.currentSiteId }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
    },
    siteChanged (siteId) {
      CompanyRepo.get(siteId)
        .then((response) => {
          this.site = response && response.data ? response.data : null
          const idx = this.sitesToDisplay.findIndex((s) => s.value === this.site.id)
          this.currentPage = idx >= 0 ? idx + 1 : 0
          this.commodityClasses = this.buildCommodityClassesByProduct()
          this.commodityCodes = this.getCommodityCodes()
        })
        .catch(() => {
          this.site = null
          this.currentPage = 0
          this.commodityClasses = []
        })
    },
    changeRegionFilter: function (selection, event) {
      selection.regionId = event // Actual assignment
      const response = CustomGroupRepo.getCustomGroupsByRegionId(this.selection.regionId)
      response
        .then((item) => {
          this.customGroupOptions = item && item.data
            ? item.data.map(global.mapUpperCase('description')).sort(global.sortBy('description')).map(global.mapOptions('id', 'description'))
            : null
          this.addUnassignedOption(this.customGroupOptions)
          this.addUnknownOption(this.customGroupOptions)
          if (this.customGroupOptions.length > 0) {
            this.selection.customGroupId = this.customGroupOptions[0].value
          }
          this.sortChecked = false
          this.filterChanged(true)
        })
        .catch(() => {
          this.customGroupOptions = null
        })
    },
    changeCGFilter: function (selection, event) {
      selection.customGroupId = event // Actual assignment
      this.sortChecked = false
      this.filterChanged(true)
    },
    async filterChanged (updateSiteSelection) {
      try {
        let response
        if (this.inventoryType === InventoryTypes.CriticalInventory || this.inventoryType === InventoryTypes.LowInventory) {
          response = await CompanyRepo.getSitesWithDOS(this.selection.customGroupId, this.selection.regionId, this.inventoryType)
        } else if (this.sortChecked && !this.isFilteringStateNotSortable() && this.inventoryType === InventoryTypes.AllConsignees) {
          response = await CompanyRepo.getSitesWithDOS(this.selection.customGroupId, this.selection.regionId, this.inventoryType)
        } else {
          response = await CompanyRepo.getSites(this.selection.customGroupId, this.selection.regionId, this.inventoryType)
        }

        if (response && response.data) {
          if (this.sortChecked && !this.isFilteringStateNotSortable()) {
            this.sites = response.data.sites.length > 0
              ? response.data.sites.map(global.mapUpperCase('companyUniqueIdAndAdress')).sort(global.sortIntBy('lowestTankDaysOfSupply')).map(global.mapOptions('id', 'companyUniqueIdAndAdress'))
              : []
          } else {
            this.sites = response.data.sites.length > 0
              ? response.data.sites.map(global.mapUpperCase('companyUniqueIdAndAdress')).sort(global.sortBy('companyUniqueIdAndAdress')).map(global.mapOptions('id', 'companyUniqueIdAndAdress'))
              : []
          }
          this.sitesToDisplay = this.sites
        }
        if (updateSiteSelection) {
          this.reloadSites = false
          if (this.sitesToDisplay.length > 0) {
            this.$router.push({ name: 'Sites', params: { siteId: this.sitesToDisplay[0].html.substring(0, this.sitesToDisplay[0].html.search('&')) }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
            this.currentSiteIndex = 0
          } else {
            this.$router.push({ name: 'Sites', params: { siteId: 'EMPTY' }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
            this.siteQuery = ''
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          this.sites = []
          this.sitesToDisplay = []
          if (updateSiteSelection) {
            this.$router.push({ name: 'Sites', params: { siteId: 'EMPTY' }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
          }
        } else {
          this.showError(global.handleError(error, 'An error occurred while fetching Site. Please try again later'))
        }
      }
    },
    navigateSite (dir) {
      this.currentSiteIndex = this.sitesToDisplay.findIndex((s) => s.value === this.selection.siteId)
      if (this.currentSiteIndex < 0) {
        return
      }

      if (dir === 'left') {
        if (this.currentSiteIndex <= 0) {
          return
        } else {
          this.currentSiteIndex--
        }
      } else if (dir === 'right') {
        if (this.currentSiteIndex >= this.sitesToDisplay.length - 1) {
          return
        } else {
          this.currentSiteIndex++
        }
      }
      const siteId = this.sitesToDisplay[this.currentSiteIndex].html.substring(0, this.sitesToDisplay[this.currentSiteIndex].html.search('&'))
      this.siteQuery = this.sitesToDisplay[this.currentSiteIndex].html.split('&nbsp;').join(' ').replace(/  +/g, ' ')
      this.currentSiteId = siteId
      this.reloadSites = false
      this.$router.push({ name: 'Sites', params: { siteId }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
    },
    async companyUpdated () {
      await this.filterChanged(false)
    },
    addUnknownOption (options) {
      options.unshift({
        value: -1,
        text: 'Unknown'
      })
    },
    addUnassignedOption (options) {
      options.unshift({
        value: null,
        text: 'Unassigned'
      })
    },
    isNavigationDisabled (dir) {
      if (!this.selection.siteId) {
        return true
      }
      const idx = this.sitesToDisplay.findIndex((s) => s.value === this.selection.siteId)
      if (idx < 0) {
        return true
      }

      if (dir === 'left') {
        return idx <= 0
      } else if (dir === 'right') {
        return idx >= this.sitesToDisplay.length - 1
      }
      return true
    },
    isFilteringStateNotSortable () {
      if ((!this.selection.regionId || this.selection.regionId === null) && (!this.selection.customGroupId || this.selection.customGroupId === null)) {
        this.sortChecked = false
        return true
      }
      if (this.selection.customGroupId === -1) {
        this.sortChecked = false
        return true
      }
      return false
    },
    inventoryChange () {
      this.sitesToDisplay = this.sites
      if (this.sitesToDisplay.length > 0) {
        this.$router.push({ name: 'Sites', params: { siteId: this.sitesToDisplay[0].html.substring(0, this.sitesToDisplay[0].html.search('&')) }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
      } else {
        this.$router.push({ name: 'Sites', params: { siteId: 'EMPTY' }, query: { cg: this.selection.customGroupId, r: this.selection.regionId, i: this.inventoryType, sorted: this.sortChecked } })
      }
    },
    async createOrder () {
      if (this.canCreateOrder) {
        const hasAccountOf = !!this.site.assignedAccountOfId
        const hasBillTo = !!this.site.assignedBillToId
        const hasShipper = !!this.site.assignedShipperId
        const hasSupplier = !!this.site.assignedSupplierId

        const isValid = this.validateShipperSupplierAndAccountOf()
        if (hasBillTo &&
            isValid) {
          const result = await this.$refs.createOrderModal.show({
            title: 'Create Order',
            companyId: this.site.id,
            timeZoneId: this.site.timeZoneId,
            tanks: this.site.tanks
          })
          if (result) {
            this.refresh()
          }
        } else {
          if (!isValid) {
            this.showWarning('Please make sure the Shipper, Supplier and Account Of are assigned to the Commodity Profile before creating a new Order')
          } else {
            const toAssign = []
            if (!hasShipper) {
              toAssign.push('Shipper')
            }
            if (!hasSupplier) {
              toAssign.push('Supplier')
            }
            if (!hasAccountOf) {
              toAssign.push('Account Of')
            }
            if (!hasBillTo) {
              toAssign.push('Bill To')
            }
            this.showWarning(`Please make sure the following values have been assigned to this Site before creating a new Order: ${toAssign.join(', ')}`)
          }
        }
      } else {
        this.showError('Current user does not have permissions to create a new order')
      }
    },
    async reviewOrder () {
      const fimOrders = await FimOrderRepo.userOrdersForReview(this.getEmail)
      if (fimOrders && fimOrders.data && fimOrders.data.length) {
        await this.$store.dispatch('orders/setOrdersToReview', fimOrders.data)
        await this.$refs.reviewOrderModal.show({
          pendingOrders: fimOrders.data,
          tanks: this.site.tanks
        })
        if (this.refreshTheSite) {
          this.refresh()
        }
      } else {
        this.showWarning('There are no Orders to review')
      }
    },
    buildCommodityClassesByProduct () {
      const commodityClasses = []
      this.site.tanks.forEach((tank) => {
        const name = tank.commodityClass
        if (!commodityClasses.find((cc) => cc.name === name)) {
          commodityClasses.push(
            {
              tankId: tank.id,
              name
            }
          )
        }
      })

      return commodityClasses
    },
    getCommodityCodes () {
      const uniqueValues = new Set()

      const codes = this.site.tanks.filter(option => {
        if (!uniqueValues.has(option.commodityCode)) {
          uniqueValues.add(option.commodityCode)
          return true
        }
        return false
      })
      return codes.map(tank => ({ commodityCode: tank.commodityCode }))
    },
    refresh () {
      this.refreshTheSite = false
      this.siteChanged(this.selection.siteId)
    },
    async openInventoryHistory () {
      if (this.site.tanks.length) {
        await this.$refs.inventoryHistoryModal.show({
          siteId: this.site.id,
          timeZoneId: this.site.timeZoneId,
          tankItems: this.site.tanks
        })
        if (this.refreshTheSite) {
          this.refresh()
        }
      } else {
        this.showWarning('Current site has no tanks')
      }
    },
    refreshCalculatorFromModal () {
      this.refreshTheSite = true
      this.refreshCalculator()
    },
    refreshCalculator () {
      this.$refs.calculatorComponent.updateData()
    },
    customGroupLabel () {
      const cgIdx = this.customGroupOptions.findIndex((s) => s.value === this.selection.customGroupId)
      return `Custom Group ( ${cgIdx + 1} of ${this.customGroupOptions.length} )`
    },
    sitesLabel () {
      return `Site ( ${this.currentSiteIndex + 1} of ${this.sitesToDisplay.length} )`
    },
    regionsLabel () {
      const regionIdx = this.regionOptions.findIndex((s) => s.value === this.selection.regionId)
      return `Region ( ${regionIdx + 1} of ${this.regionOptions.length})`
    },
    validateShipperSupplierAndAccountOf () {
      const hasShipperSupplierandAccountOf = this.site.tanks.map(tank => {
        return (tank.assignedShipperId && tank.assignedSupplierId && tank.assignedAccountOfId) ?? false
      })
      return hasShipperSupplierandAccountOf.every(item => item)
    }
  },
  computed: {
    sitesNavLabel () {
      return `( ${this.currentSiteIndex + 1} of ${this.sitesToDisplay.length} )`
    },
    ordersToReviewCount () {
      return this.$store.getters['orders/amountOfOrdersToReview']
    },
    sitesForAutoComplete () {
      return this.sitesToDisplay.map((site) => {
        return { display: site.html.split('&nbsp;').join(' ').replace(/  +/g, ' '), value: site.value }
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.sites {
  font-size: 14px;

  .selection-container {
    color: $white;
    background-color: $kag-dark-blue-color;

    .inventory {
      display: inline-block;
    }

    .btn-sitesort {
      float: right;
    }
  }

  .site-details-container {
    border: 1px $kag-grey-color solid;
  }

  .create-order-container {
    margin: 0.25rem 0 !important;

    .create-order-btn,
    .review-order-btn,
    .inventory-history-btn {
      z-index: 1;
      position: absolute;
    }

    .review-order-btn {
      &:not(.no-tanks) {
        margin-right: 130px;
      }
    }

    .inventory-history-btn {
      margin-right: 177px;

      &:not(.no-tanks) {
        margin-right: 307px;
      }
    }
  }
}

.extra-space{
  margin-right: 330px!important;
}

.special-highlight-class {
  font-weight: 900;
}

.vbst-item:hover {
  background: #1967D2;
  color: #fff !important;
}

.site-search:focus {
    outline-style: solid;
    outline-color: #939598;
    outline-width: thick;
}
.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
}
.text-white {
  color: #fff !important;
}

</style>
