<template>
  <div id="create-order" class="create-order">
    <b-modal ref="confirmModal" :size="modalSize" no-close-on-backdrop hide-footer :static="true" :title="title">
      <b-form novalidate class="order-form" @submit.prevent="confirm">
        <b-container>
          <b-row>
            <b-col cols="12" col>
              <table class="table b-table table-bordered table-sm b-table-no-border-collapse mb-0">
                <thead>
                  <tr>
                    <th>
                      Retain Date
                    </th>
                    <th>
                      Retain Time
                    </th>
                    <th>
                      Runout Date
                    </th>
                    <th>
                      Runout Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="order-retain-date"
                          v-model="retainDateString"
                          type="text"
                          size="sm"
                          placeholder="MM/DD/YY"
                          autocomplete="off"
                          maxlength="8"
                          :state="retainDateState"
                          @input="onRetainDateChange"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            button-only
                            left
                            id="order-retain-date-icon"
                            size="sm"
                            value-as-date
                            hide-header
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="en"
                            v-model="order.retainDate"
                            @context="onRetainDateContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="order-retain-time"
                          v-model="retainTimeString"
                          type="text"
                          size="sm"
                          placeholder="HH:MM"
                          autocomplete="off"
                          maxlength="5"
                          :state="retainTimeState"
                          @input="onRetainTimeChange"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-timepicker
                            button-only
                            left
                            id="order-retain-time-icon"
                            size="sm"
                            hide-header
                            no-close-button
                            locale="en-US-u-hc-h23"
                            :hour12="false"
                            v-model="order.retainTime"
                            @context="onRetainTimeContext"
                          ></b-form-timepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="order-runout-date"
                          v-model="runoutDateString"
                          type="text"
                          size="sm"
                          placeholder="MM/DD/YY"
                          autocomplete="off"
                          maxlength="8"
                          :state="runoutDateState"
                          @input="onRunoutDateChange"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            button-only
                            left
                            id="order-runout-date-icon"
                            size="sm"
                            value-as-date
                            hide-header
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="en"
                            v-model="order.runoutDate"
                            @context="onRunoutDateContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="order-runout-time"
                          v-model="runoutTimeString"
                          type="text"
                          size="sm"
                          placeholder="HH:MM"
                          autocomplete="off"
                          maxlength="5"
                          :state="runoutTimeState"
                          @input="onRunoutTimeChange"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-timepicker
                            button-only
                            left
                            id="order-runout-time-icon"
                            size="sm"
                            hide-header
                            no-close-button
                            locale="en-US-u-hc-h23"
                            :hour12="false"
                            v-model="order.runoutTime"
                            @context="onRunoutTimeContext"
                          ></b-form-timepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" col>
              <table class="table b-table table-responsive table-bordered table-sm b-table-no-border-collapse mb-0">
                <thead>
                  <tr>
                    <th>
                      Product
                    </th>
                    <th v-for="tank in tankOrders" :key="tank.commodityClass">
                      {{ tank.commodityClass }}
                      <div>{{ tank.commodityCode }}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Gallons
                    </td>
                    <td v-for="tank in tankOrders" :key="tank.commodityClass">
                      <b-form-group
                        :id="'tankGallons-fieldset' + tank.id"
                        class="tankGallons-form-group"
                        v-bind:class="{ 'warn': !!gallonsWarnState(tank) }"
                      >
                        <b-form-input
                          :id="'order-' + tank.commodityClass"
                          class="order-input tankGallons-input"
                          size="sm"
                          v-model="tank.gallons"
                          type="number"
                          :max="tank.safefillQty"
                          min="0"
                          :disabled="tank.outOfService"
                          :class="{'oos-disabled': tank.outOfService }"
                        ></b-form-input>
                        <font-awesome-icon
                          class="tankGallons-icon"
                          icon="circle-info"
                          v-if="!!gallonsWarnState(tank)"
                          v-b-tooltip.hover="gallonsTooltipState(tank)"
                        />
                      </b-form-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" col>
              <table class="table b-table table-bordered table-sm b-table-no-border-collapse">
                <thead>
                  <tr>
                    <th>
                      Customer Shift
                    </th>
                    <th>
                      Release Number
                    </th>
                    <th>
                      Order Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="customer-shift-number"
                          v-model="order.customerShift"
                          type="text"
                          size="sm"
                          autocomplete="off"
                          maxlength="30"
                        ></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="release-number"
                          v-model="order.releaseNumber"
                          type="text"
                          size="sm"
                          autocomplete="off"
                          maxlength="30"
                        ></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group>
                        <b-form-input
                          id="order-remarks"
                          v-model="remarks"
                          type="text"
                          size="sm"
                          autocomplete="off"
                          maxlength="200"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button class="btn-secondary" variant="kag-light-gray" size="sm" @click="openOrderRemarksPopUp()">
                            <font-awesome-icon  icon="pen-to-square" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" col>
              <table class="table b-table table-bordered table-sm b-table-no-border-collapse">
                <thead>
                  <tr>
                    <th>
                      Order Priority
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b-form-select id="order-priority" v-model="order.priority" :options="priorityOptions" :select-size="2"></b-form-select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" col offset-lg="4" lg="4" class="pt-2 pb-2">
              <div class="d-flex">
                <b-button class="mt-2 mr-3" block variant="outline-kag-dark-blue" type="submit">Save</b-button>
                <b-button id="confirm-modal-cancel-btn" block variant="outline-kag-red" @click="cancel">Cancel</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </b-modal>
    <OrderRemarks ref="orderRemarksModal" @confirmedValue="receiveOrderRemarks"/>
  </div>
</template>

<style lang="scss">
.tankGallons-form-group {
  &.warn {
   .tankGallons-input {
     display: inline-block;
     width: calc(100% - 30px);
     margin-right: 5px;
   }
  .tankGallons-icon {
    display: inline-block;
    color: $kag-warning-color;
   }
  }
}

</style>

<script>
import moment from 'moment-timezone'

import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import OrderRemarks from '@/components/shared/modal/OrderRemarks.vue'
const TankRepo = RepositoryFactory.get('TankRepository')
const FimOrderRepo = RepositoryFactory.get('FimOrderRepository')

export default {
  name: 'CreateOrder',
  components: {
    OrderRemarks
  },
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  data () {
    return {
      title: '',
      companyId: 0,
      timeZoneId: '',
      retainDateString: '',
      retainTimeString: '',
      runoutDateString: '',
      runoutTimeString: '',
      order: {},
      tanks: [],
      originalTanks: [],
      tankOrders: [],
      fimOrder: {},
      // Private variables
      resolvePromise: () => {},
      remarks: null,
      priorityOptions: [
        { text: 'Available', value: 'UNK' },
        { text: 'Priority', value: '1' }
      ]
    }
  },
  computed: {
    retainDateState () {
      if (this.retainDateString.match(global.shortDateRegex)) {
        return null
      }

      return false
    },
    retainTimeState () {
      if (this.retainTimeString.match(global.time23Regex)) {
        return null
      }

      return false
    },
    runoutDateState () {
      if (this.runoutDateString.match(global.shortDateRegex)) {
        return null
      }

      return false
    },
    runoutTimeState () {
      if (this.runoutTimeString.match(global.time23Regex)) {
        return null
      }

      return false
    },
    canConfirm () {
      return this.tankOrders && this.tankOrders.some((to) => to.gallons > 0)
    },
    orderedGallonsInNotSafeFill () {
      let gallonsInNotSafeFill = false
      this.tankOrders.forEach((to) => {
        if (to.gallons > to.safefillQty || to.gallons < 0) {
          gallonsInNotSafeFill = true
          return false
        }
      })
      return gallonsInNotSafeFill
    },
    modalSize () {
      return this.tankOrders.length < 9 ? 'lg' : 'xl'
    }
  },
  methods: {
    buildTanks () {
      this.tankOrders = this.tanks.map((tank) => {
        let commodityClass = tank.commodityClass
        if (this.tanks.filter((t) => t.commodityClass === commodityClass).length > 1) {
          commodityClass += ` (${tank.tankNumber})`
        }

        return {
          commodityClass,
          commodityCode: tank.commodityCode,
          gallons: 0,
          safefillQty: tank.safefillQty,
          tankId: tank.id,
          outOfService: tank.outOfService
        }
      })
      const now = new Date()
      now.setDate(now.getDate() + 1)
      this.order.retainDate = now
      this.order.retainTime = '00:00:00'
      this.order.runoutDate = now
      this.order.runoutTime = '23:59:00'
      this.retainDateString = global.buildShortDateString(now)
      this.runoutDateString = global.buildShortDateString(now)
      this.order.priority = 'UNK'
    },
    buildFimOrders () {
      this.tankOrders = this.fimOrder.fimOrderProducts.map((fimOrder, index) => {
        const tankInfo = this.getTankInfo(fimOrder.tankId)
        return {
          id: fimOrder.id,
          commodityClass: fimOrder.commodityClass,
          gallons: fimOrder.gallons,
          safefillQty: tankInfo?.safefillQty ?? 0,
          tankId: fimOrder.tankId,
          outOfService: tankInfo?.outOfService ?? false
        }
      })
      const retainDateTime = new Date(this.fimOrder.retainDateTime)
      this.order.retainDate = retainDateTime
      this.order.retainTime = `${('0' + retainDateTime.getHours()).slice(-2)}:${('0' + retainDateTime.getMinutes()).slice(-2)}:00`
      const runoutDateTime = new Date(this.fimOrder.runoutDateTime)
      this.order.runoutDate = runoutDateTime
      this.order.runoutTime = `${('0' + runoutDateTime.getHours()).slice(-2)}:${('0' + runoutDateTime.getMinutes()).slice(-2)}:00`
      this.order.customerShift = this.fimOrder.customerShift
      this.order.releaseNumber = this.fimOrder.releaseNumber
      this.remarks = this.fimOrder.orderRemarks
      this.order.priority = this.fimOrder.priority
    },
    show (opts = {}) {
      this.title = opts.title
      if (opts.tanks && opts.tanks.length) {
        this.companyId = opts.companyId
        this.timeZoneId = opts.timeZoneId
        this.tanks = opts.tanks
        this.buildTanks()
      } else if (opts.fimOrder) {
        this.timeZoneId = opts.timeZoneId
        this.fimOrder = opts.fimOrder
        this.getTanksFromOrder(this.fimOrder)
      }

      this.$refs.confirmModal.show()

      return new Promise((resolve) => {
        this.resolvePromise = resolve
      })
    },
    clear () {
      this.title = ''
      this.companyId = 0
      this.order = {}
      this.tanks = []
      this.tankOrders = []
      this.fimOrder = undefined
      this.remarks = null
    },
    cancel () {
      this.clear()
      this.$refs.confirmModal.hide()
      this.resolvePromise(false)
    },
    async confirm () {
      if (this.canConfirm) {
        if (!this.orderedGallonsInNotSafeFill) {
          const retainTime = this.order.retainTime.split(':')
          const retainDateObj = {
            year: this.order.retainDate.getFullYear(),
            month: this.order.retainDate.getMonth(),
            day: this.order.retainDate.getDate(),
            hour: +retainTime[0],
            minute: +retainTime[1],
            second: +retainTime[2]
          }
          const retainDate = moment.tz(retainDateObj, this.timeZoneId)
          const runoutTime = this.order.runoutTime.split(':')
          const runoutDateObj = {
            year: this.order.runoutDate.getFullYear(),
            month: this.order.runoutDate.getMonth(),
            day: this.order.runoutDate.getDate(),
            hour: +runoutTime[0],
            minute: +runoutTime[1],
            second: +runoutTime[2]
          }
          const runoutDate = moment.tz(runoutDateObj, this.timeZoneId)
          if (runoutDate >= retainDate) {
            if (this.tanks && this.tanks.length) {
              const createRequest = {
                userName: this.getEmail,
                companyId: this.companyId,
                status: 'NEW',
                retainDateTime: retainDate.format('yyyy-MM-DDTHH:mm:ss'),
                runoutDateTime: runoutDate.format('yyyy-MM-DDTHH:mm:ss'),
                customerShift: this.order.customerShift,
                releaseNumber: this.order.releaseNumber,
                orderRemarks: this.order.orderRemarks,
                priority: this.order.priority,
                fimOrderProducts: this.tankOrders.map((to) => {
                  if (!to.gallons) {
                    to.gallons = 0
                  }
                  return to
                })
              }

              FimOrderRepo.create(createRequest)
                .then(() => {
                  this.finishOrderForm('New order has been added')
                  this.$store.dispatch('orders/addOrdersToReview', createRequest)
                })
                .catch((error) => {
                  this.showError(global.handleError(error, 'An error occurred while adding new order. Please try again later'))
                })
            } else if (this.fimOrder) {
              const updateRequest = { ...this.fimOrder }
              updateRequest.retainDateTime = retainDate.format('yyyy-MM-DDTHH:mm:ss')
              updateRequest.runoutDateTime = runoutDate.format('yyyy-MM-DDTHH:mm:ss')
              updateRequest.customerShift = this.order.customerShift
              updateRequest.releaseNumber = this.order.releaseNumber
              updateRequest.orderRemarks = this.order.orderRemarks
              updateRequest.priority = this.order.priority
              this.tankOrders.forEach((to) => {
                const idx = updateRequest.fimOrderProducts.findIndex((fop) => fop.id === to.id)
                if (idx >= 0) {
                  updateRequest.fimOrderProducts[idx].gallons = +to.gallons
                }
                updateRequest.fimOrderProducts[idx].tankId = to.tankId
              })

              FimOrderRepo.update(updateRequest)
                .then(() => {
                  this.finishOrderForm('Order updated successfully')
                  this.$store.dispatch('orders/updateOrderToReview', updateRequest)
                })
                .catch((error) => {
                  this.showError(global.handleError(error, 'An error occurred while updating order. Please try again later'))
                })
            }
          } else {
            this.showError('Retain date and time cannot be greater than the Runout date and time.')
          }
        } else {
          this.showError('Cannot create an order larger than tank size or safe fill, check the tank gallons.')
        }
      } else {
        this.showError('Minimum of 1 gallon to be assigned, for at least 1 product at the site.')
      }
    },
    onRetainDateChange (text) {
      if (text.match(global.sixDigitRegex)) {
        this.retainDateString = `${text.substring(0, 2)}/${text.substring(2, 4)}/${text.substring(4, 6)}`
        this.onRetainDateChange(this.retainDateString)
      } else {
        if (text.match(global.shortDateRegex)) {
          const split = text.split('/')
          this.order.retainDate = new Date((+split[2] + 2000), +split[0] - 1, +split[1])
        }
      }
    },
    onRetainDateContext (ctx) {
      this.retainDateString = ctx.selectedDate ? `${('0' + (+ctx.selectedDate.getMonth() + 1)).slice(-2)}/${('0' + ctx.selectedDate.getDate()).slice(-2)}/${ctx.selectedDate.getFullYear().toString().substring(2, 4)}` : ''
    },
    onRetainTimeChange (text) {
      if (text.match(global.fourDigitRegex)) {
        this.retainTimeString = `${text.substring(0, 2)}:${text.substring(2, 4)}`
        this.onRetainTimeChange(this.retainTimeString)
      } else {
        if (text.match(global.time23Regex)) {
          const [hours, minutes] = text.split(':')
          this.order.retainTime = `${hours}:${minutes}:00`
        }
      }
    },
    onRetainTimeContext (ctx) {
      this.retainTimeString = ctx.formatted
    },
    onRunoutDateChange (text) {
      if (text.match(global.sixDigitRegex)) {
        this.runoutDateString = `${text.substring(0, 2)}/${text.substring(2, 4)}/${text.substring(4, 6)}`
        this.onRunoutDateChange(this.runoutDateString)
      } else {
        if (text.match(global.shortDateRegex)) {
          const split = text.split('/')
          this.order.runoutDate = new Date((+split[2] + 2000), +split[0] - 1, +split[1])
        }
      }
    },
    onRunoutDateContext (ctx) {
      this.runoutDateString = ctx.selectedDate ? `${('0' + (+ctx.selectedDate.getMonth() + 1)).slice(-2)}/${('0' + ctx.selectedDate.getDate()).slice(-2)}/${ctx.selectedDate.getFullYear().toString().substring(2, 4)}` : ''
    },
    onRunoutTimeChange (text) {
      if (text.match(global.fourDigitRegex)) {
        this.runoutTimeString = `${text.substring(0, 2)}:${text.substring(2, 4)}`
        this.onRunoutTimeChange(this.runoutTimeString)
      } else {
        if (text.match(global.time23Regex)) {
          const [hours, minutes] = text.split(':')
          this.order.runoutTime = `${hours}:${minutes}:00`
        }
      }
    },
    onRunoutTimeContext (ctx) {
      this.runoutTimeString = ctx.formatted
    },
    gallonsWarnState (tank) {
      return tank.gallons > tank.safefillQty || !!(tank.gallons % 1) || tank.gallons < 0
    },
    gallonsTooltipState (tank) {
      if (tank.gallons % 1) {
        return 'Gallons cannot contain decimal places'
      } else if (tank.gallons < 0) {
        return 'Gallons cannot be negative number'
      } else if (tank.gallons > tank.safefillQty) {
        return 'Gallons are greater than Safe Fill'
      } else if (tank.gallons > tank.Inventory) {
        return 'Gallons are greater than Inventory'
      }
    },
    finishOrderForm (message) {
      this.showSuccess(message)
      this.clear()
      this.$refs.confirmModal.hide()
      this.resolvePromise(true)
      this.$emit('refresh-calculator')
    },
    getTankInfo (id) {
      var tankInfo = null
      this.originalTanks.forEach((tank) => {
        if (tank.id === id) {
          tankInfo = tank
        }
      })
      return tankInfo
    },
    getTanksFromOrder (fimOrder) {
      TankRepo.getTanksByCompanyId(fimOrder.companyId)
        .then((response) => {
          if (response && response.data && response.data.length) {
            this.originalTanks = [...response.data]
          }
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred getting Tanks from order'))
        })
        .finally(() => {
          this.buildFimOrders()
        })
    },
    async openOrderRemarksPopUp () {
      this.$refs.orderRemarksModal.show({
        orderRemarks: this.order.orderRemarks
      })
    },
    receiveOrderRemarks (orderRemarks) {
      this.remarks = orderRemarks
    }
  },
  watch: {
    remarks (newVal) {
      this.order.orderRemarks = newVal
    }
  }
}
</script>
